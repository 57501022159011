@charset 'UTF-8';
@import "/Users/minhminh/Website/vhost/lua-viet-corp/src/scss/utility/_media-queries.scss";
@import "/Users/minhminh/Website/vhost/lua-viet-corp/src/scss/utility/_mixin.scss";

/*-----------------------------------------------------------
MV
-------------------------------------------------------------*/
.mv{
	width: 100%;
	overflow: hidden;
	&__slider{
		width: 100%;
		@include flexbox;
		overflow: hidden;
		min-height: 387px;
		@include aspect-ratio(1440,387);
		@include max-screen(1024px){
			min-height: 210px;
			&:before{padding-top: (210/375)*100%;}
		}
		.owl-stage-outer{
			position: absolute;
			top:0;left: 0;
			height: 100%;
			width: 100%;
		}
		.owl-stage{
			@include flexbox;
			width: 100%;
			height: 100%;
		}
		.item{
			display: block;
			min-width: 100vw;
			width: 100%;
			height: 100%;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	}
}
/*-----------------------------------------------------------
Body
-------------------------------------------------------------*/
.line-end{
	height: 1px;
	// background: red;
	display: block;
	position: relative;
  margin: 0 auto -96px;
  @include screen(768px,1024px){margin-bottom: -73px;}
  // @include screen(768px,1000px){margin-bottom: -59px;}
  @include max-screen(1000px){margin: 0 auto -393px;}
  + section{
  	padding-top: 208px;
  	@include screen(768px,1024px){padding-top: 150px;}
  	// @include screen(768px,1000px){padding-top: 120px};
  	@include max-screen(1000px){padding-top: 474px;}
  }
}
.wrap-booking{
  width: calc(100% - 32px);
  overflow: hidden;
  max-width: 1232px;
  height: 170px;
	position: relative;
  margin: -76px auto 0;
	@include screen(768px,1024px){
		margin-top: -70px;
		height: 136px;
	}
	// @include screen(768px,1000px){
	// 	width: calc(100% - 30px);
	// 	height: 123px;
	// }
	@include max-screen(1000px){
		margin: -49px auto 0;
		height: auto;
	}
}
.booking{
	width: 100%;
  background: $blue;
  position: absolute;
  left: 0; top: 0;
  z-index: 1;
  padding: 33px 36px 41px;
  @include screen(768px,1024px){padding: 20px;}
  // @include screen(768px,1000px){padding: 15px;}
  @include max-screen(1000px){
  	position: relative;
  	padding: 31px 16px 24px;
  }
  .inner{
  	width: 100%;
  	max-width: 1232px;
  	margin: auto;
  	@include flexbox;
  	justify-content: space-between;
  	flex-wrap: wrap;
  	align-items: flex-start;
  }
  &__tour{
    width: 100%;
    .tour-select{
  		width: 100%;
  		height: 44px;
  		@include multiLineEllipsis(1);
  		padding: 12px 12px;
  		background: #fff url(../../img/common/header/ar_blue.svg) right 12px center/10px 5px no-repeat;
  		appearance: none;
  		border: none;
  		box-shadow: none;
  		border-radius: 0;
  		position: relative;
  		@include font(14,1.2,0,bold);
  		color: $blue;
    	display: none;
    }
    .tour-radio{
    	@include flexbox;
    	justify-content: flex-start;
    	margin-bottom: 21px;
    	// @include screen(768px,1000px){margin-bottom: 13px;}
    	@include max-screen(1000px){
    		padding: 0 14px;
    		margin-bottom: 21px;
    	}
    	@include max-screen(370px){padding: 0;}
    	.chkBox{
    		&:not(:first-child){
    			margin-left: 31px;
    			@include SP{margin-left: 40px;}
    			@include max-screen(370px){margin-left: 20px;}
    		}
    	}
    }
  }
  &__detail{
    width: 100%;
    @include flexbox;
    justify-content: space-between;
    @include max-screen(1000px){flex-wrap: wrap;}
    >div,>label{
      margin-right: 10px;
      @include SP{margin-right: 0;}
    }
    .place{
      @include flexbox;
      justify-content: space-between;
      width: calc(100% - (10px*3) - 150px - 190px - 223px);
      @include screen(768px,1024px){
      	width: calc(100% - (10px*3) - 150px - 160px - 130px);
      }
      // @include screen(768px,1000px){
      // 	width: calc(100% - (10px*3) - 140px - 140px - 60px);
      // }
      @include max-screen(1000px){
      	width: 100%;
      	flex-wrap: wrap;
      	.input:nth-child(2) .txt{padding-left: 90px;}
      }
      .input{
      	width: calc(50% - 24px);
        @include screen(768px,1024px){width: calc(50% - 15px);}
        @include max-screen(1000px){width: 100%;}
        &:nth-child(2){
          position: relative;
          &:before{
          	content:"";position: absolute;
          	width: 17px;height: 17px;
          	left: -34px;
          	top: calc(50% - 4px);
          	background: url(../../img/common/icon/ico_change_babyblue.svg) center/100% 100% no-repeat;
          	@include screen(768px,1024px){left: -25px;}
          	@include max-screen(1000px){content: none;}
          }
        }
      }
    }
    .input{
      border: 1px solid #8B8B8B;
      background: #fff;
      padding: 9px 10px 7px 53px;
      display: block;
      position: relative;
      @include font(0,0,0);
      @include screen(768px,1024px){padding-left: 43px;}
      // @include screen(768px,1000px){padding: 12px 10px 7px 33px}
      @include max-screen(1000px){
      	width: 100%;
      	border: none;
      	padding: 16px 35px 17.5px 12px;
      	@include flexbox;
      	justify-content: flex-start;
      	align-items: center;
      	margin-bottom: 12px;
      }
      .ttl{
        @include font(14,1.5,0,700);
        display: block;
        color: $blue;
        // @include screen(768px,1000px){font-size: 13px;}
        @include max-screen(1000px){
        	display: inline-block;
        	white-space: nowrap;
        	font-size: 15px;
        	padding-right: 8px;
        }
        @include max-screen(370px){font-size: 14px;}
      }
      .txt{
      	position: absolute;
      	top:0;left: 0;
        width: 100%;
        // min-height: 18px;
        padding: 29px 10px 7px 53px;
        display: inline-block;
        margin: -2px 0 0 0;
        @include font(14,1.5,0,500);
        border: none;
        @include add_prefix(appearance, none);
        background: none;
        border-radius: 0;
        box-shadow: none;
        color: rgba(1, 56, 121, 0.4);
        &::-webkit-input-placeholder { opacity: 1; color: rgba(1, 56, 121, 0.4);}
        &::-moz-placeholder { opacity: 1; color: rgba(1, 56, 121, 0.4);}
        &::-ms-input-placeholder { opacity: 1; color: rgba(1, 56, 121, 0.4);}
        &::-moz-placeholder { opacity: 1; color: rgba(1, 56, 121, 0.4);}
        @include screen(768px,1024px){padding-left: 43px;}
        @include screen(768px,1000px){padding: 26px 10px 7px 33px}
        // @include screen(768px,1000px){
        // 	font-size: 13px;
        // 	margin-top: 4px;
        // }
        @include max-screen(1000px){
        	margin: 0;
        	padding: 16px 35px 17.5px 78px;
        }
        @include max-screen(370px){}
        &.changeColor{
        	color: #222;
        }
        &.datepicker{cursor: pointer;}
      }
      &--numberOfDays,&--date{
      	cursor: pointer;
      	background: #fff url(../../img/common/icon/ico_calendar_blue.svg) left 15px center/28px 28px no-repeat;
      	@include screen(768px,1024px){background-position: left 8px center;}
      	// @include screen(768px,1000px){background-position: left 4px center;background-size: 23px 23px;}
      	@include max-screen(1000px){
      		background-size: 24px 24px;
      		background-position: right 10px center;
      	}
      }
      &--numberOfDays{
        width: 150px;
        // @include screen(768px,1000px){width: 140px;}
        @include max-screen(1000px){
        	width: 100%;
        	.txt{padding-top: 17px}
        }
      }
      &--date{
        width: 190px;
        @include screen(768px,1024px){width: 160px;}
        // @include screen(768px,1000px){width: 140px;}
        @include max-screen(1000px){width: 100%;}
      }
      &--gpa{
      	background: #fff url(../../img/common/icon/ico_location_blue.svg) left 15px center/28px 28px no-repeat;
      	@include screen(768px,1024px){background-position: left 8px center;}
      	// @include screen(768px,1000px){background-position: left 4px center;background-size: 23px 23px;}
      	@include max-screen(1000px){background-size: 24px 24px ; background-position: right 10px center;}
      }
    }
    .c-btn{
      width: 223px;
      padding: 0;
      @include screen(768px,1024px){width: 130px;}
      // @include screen(768px,1000px){
      // 	width: 60px;
      // 	min-height: 60px;
      // 	span{
      // 		width: 20px;height: 20px;
      // 		font-size: 0;padding: 0;
      // 	}
      // }
      @include max-screen(1000px){
      	margin-top: 8px;
      	width: 100%;
      	max-width: 100%;
      }
    }
    &[data-tour="du-lich-trong-nuoc"]{
    	select[name="diem_di"]{
    		option[data-tour="du-lich-nuoc-ngoai"]{display: none;}
    		option[data-tour="du-lich-trong-nuoc"]{display: block;}
    	}
    	// select[data-tour="du-lich-nuoc-ngoai"]{display: none;}
    }
    &[data-tour="du-lich-nuoc-ngoai"]{
    	select[name="diem_di"]{
    		option[data-tour="du-lich-nuoc-ngoai"]{display: block;}
    	}
    	// select[data-tour="du-lich-trong-nuoc"]{display: none;}
    }
  }
}
body.bookingFixed .booking{
	@include min-screen(1001px){
		transition: all 0.3s;
		position: fixed;
		top: 72px; left: 0;
		z-index: 99;
		width: 100%;
		max-width: 100%;
		margin: 0 auto;
		padding: 15px 15px 13px;
		@include screen(768px,1200px){ top: 58px;}
		.inner{
			flex-wrap: nowrap;
		}
		.booking__tour{
			width: 157px;
			position: relative;
			.tour-radio {display: none;}
			.tour-select{
				display: block;
			}
		}
		.booking__detail{
			width: calc(100% - 165px);
			align-items: flex-start;
			.place{
				width: calc(100% - 494px - 8px);
				@include screen(768px,1024px){ width: calc(100% - 400px - 8px);}
				.input{
					position: relative;
					&:after{
						content: "";position: absolute;
						width: 10px;
						height: 5px;
						right: 10px;
						top: calc(50% - 1px);
						background: url(../../img/common/header/ar_blue.svg) center/100% auto no-repeat;
					}
					&--gpa{
						width: calc(50% - 20px);
						background-size: 24px 24px;
						background-position: left 7px center;
					}
					&:nth-child(2):before{
						left: -30px; top: calc(50% - 7px);
					}
				}
			}
			.input{
				display: inline-block;
				border-color: #fff;
				padding: 0;
				.ttl{ display: none;}
				.select, .txt{
					position: relative;
					margin-top: 0;
					color: $blue;
					opacity: 1;
					font-weight: bold;
					padding: 11px 10px 10px 40px;
					&::-webkit-input-placeholder { opacity: 1; color:  $blue;}
					&::-moz-placeholder { opacity: 1; color: $blue;}
					&::-ms-input-placeholder { opacity: 1; color: $blue;}
					&::-moz-placeholder { opacity: 1; color: $blue;}
				}
				&--date{
					width: 174px;
					margin-right: 8px;
					@include screen(768px,1024px){width: 140px;}
					// @include screen(768px,1000px){padding: 15px;}
				}
				&--numberOfDays{
					width: 142px;
					margin-right: 8px;
					@include screen(768px,1024px){ width: 140px;}
				}
				&--calendar{
					cursor: pointer;
					background-size: 24px 24px;
					background-position: left 6px center;
				}
			}
			.c-btn{
				width: 162px;
				min-height: 44px;
				top: 0;
				margin: 0;
				@include screen(768px,1024px){ width: 120px;}
				span{
					font-size: 16px;
					letter-spacing: 0.03em;
					font-weight: 500;
				}
			}
		}
	}
}
/*-----------------------------------------------------------
Special Offer
-------------------------------------------------------------*/
.sec-specialOffer{
	padding: 100px 0 120px;
	background: url(../../img/top/special_offer_bg.jpg) center/cover no-repeat;
	@include screen(768px,1000px){
		padding: 80px 0;
		background-image: url(../../img/top/special_offer_bg_sp.jpg);
		overflow: hidden;
	}
	@include SP{padding: 80px 0;}
	.wcm{
		overflow: hidden;
		padding: 0 62px;
		max-width: 1370px;
		@include screen(768px,1024px){padding: 0 45px}
		@include SP{padding: 0 24px;}
	}
	.specialOffer-slider{
		margin: 50px auto 0;
		position: relative;
		@include SP{
			margin: 32px auto 0;
			.owl-stage-outer{overflow: visible;}
		}
		.owl-item{
			width: 33.33%;
			padding: 0 8px;
			@include SP{width: 100%;}
		}
		.item{
			width: 100%;
			display: block;
			.hoverImg{
				width: 100%;
				@include aspect-ratio(400,246);
				@include SP{
					&:before{padding-top: (190/311) * 100%;}
				}
			}
			img{
				position: absolute;
				top:0;left: 0;
				width: 100%;height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
		.owl-nav{
			margin: 0;
			padding: 0;
			button{
				position: absolute;
				top: calc(50% - 16px);
				padding: 0;
				margin: 0;
				box-shadow: none;
				@include add_prefix(appearance, none);
				@include font(0,0,0);
				width: 32px; height: 32px;
				background: url(../../img/common/icon/ico_slide.svg) center/100% 100% no-repeat;
				span{display: none;}
				&.owl-prev{
					left: -53px;
					@include screen(768px,1024px){left: -35px}
				}
				&.owl-next{
					right: -53px;
					@include transform_c(rotate(180deg));
					@include screen(768px,1024px){right: -35px;}
				}
			}
		}
	}
}
/*-----------------------------------------------------------
Tour
-------------------------------------------------------------*/
.sec-tour{
	padding: 100px 0 21px;
	@include screen(768px,1000px){padding: 80px 0 0;}
	@include SP{padding: 80px 0 0}
	.tour-lst{
		margin: 50px auto 0px;
		@include SP{margin-top: 33px;}
		.item{
			margin-bottom: 65px;
			line-height: calc(24/6);
			// @include PC{.item__ttl{@include multiLineEllipsis(1);}}//Clear
			@include SP{
				margin-bottom: 33.5px;
				&:nth-child(5),&:nth-child(6){display: none;}
			}
		}
	}
	.c-btn{margin: 0 auto;}
}
/*-----------------------------------------------------------
FAVORITE
-------------------------------------------------------------*/
.sec-favorite{
	overflow: hidden;
	padding: 100px 0 100px;
	@include screen(768px,1000px){padding: 80px 0}
	@include SP{padding: 82px 0 72px;}
	.lst{
		margin-top: 48px;
		width: 100%;
		left: 2px;
		display: block;
		@include aspect-ratio(1230,608);
		@include SP{
			@include flexbox;
			justify-content: space-between;
			flex-wrap: wrap;
			left: 0;
			margin-top: 31px;
			&:before{content: none;}
		}
		&__item{
			position: absolute;
			@include add_prefix(backface-visibility,hidden);
			@include transform_c(translate3D(0,0,0));
			@include SP{
				position: relative;
				margin-bottom: 8px;
			}
			&:after{
				content: "";position: absolute;
				width: 100%;height: 100%;
				top: 0;left: 0;
				background: rgba(0,0,0,0.6);
				z-index:0;
				opacity: 0;
				@include transition_c(all 0.4s);
			}
			.thumb{
				@include thumb;
			}
			.ttl{
				position: absolute;
				bottom: 0;left: 0;
				z-index: 1;
				@include transition_c(all 0.4s);
				display: inline-block;
				white-space: nowrap;
				font-style: normal;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 1;
				color: #fff;
				background-position: left center;
				background-image: url(../../img/common/icon/ico_plane_white.svg);
				background-repeat: no-repeat;
				// @include add_prefix(backface-visibility,hidden);
				// @include transform_c(translate3D(0,0,0));
			}
			@include PC{
				&:nth-child(1),&:nth-child(4){width: 49.35%;}
				&:nth-child(2),&:nth-child(3){width: 24.03%;}
				&:nth-child(2){top: 0; right: 25.32%;}
				&:nth-child(3){top: 0; right: 0;}
				&:nth-child(4){bottom:0; right: 0;}
			}
			@include SP{
				&:nth-child(2),&:nth-child(3){width: calc(50% - 4px);}
				&:nth-child(2){margin-right: 8px;}
				&:nth-child(4){
					width: 100%;
					.ttl{
						margin: 0 0 26px 24px !important;
						@include max-screen(370px){margin: 0 0 12px 12px !important}
					}
				}
			}
			&:not(:nth-child(4)) .thumb{
				@include aspect-ratio(1,1);
			}
			@include SP{ &:nth-child(1) .thumb{@include aspect-ratio(343,257);}}
			&:nth-child(4) .thumb{
				@include aspect-ratio(608,296);
				@include SP{
					&:before{padding-top: (193/343)*100%;}
				}
			}
			&:nth-child(1){
				top:0;left: 0;
				@include SP{width: 100%;}
				.ttl{
					margin: 0 0 22px 15px;
					font-size: 32px;
					background-size: 44px 44px;
					padding-left: 51px;
					@include screen(768px,1024px){margin: 0 0 15px 15px;}
					@include screen(768px,1000px){
						margin: 0 0 18px 15px;
						font-size: 26px;
						background-size: 36px 36px;
						padding-left: 40px;
					}
					@include SP{
						font-size: 22px;
						background-size: 26px 26px;
						margin: 0 0 26px 24px;
						padding-left: 34px;
					}
					@include max-screen(370px){margin: 0 0 15px 15px}
				}
				@include HOVER {
					.ttl{
						font-size: 48px;
						background-size: 58px 58px;
						padding-left: 61px;
					}
				}
			}
			&:not(:nth-child(1)){
				.ttl{
					margin: 0 0 17px 16px;
					padding-bottom: 4px;
					font-size: 24px;
					background-size: 30px 30px;
					padding-left: 36px;
					@include screen(768px,1024px){
						margin: 0 0 12px 12px;
						font-size: 20px;
						background-size: 26px 26px;
						padding-left: 30px;
						padding-bottom: 0px;
					}
					@include screen(768px,1000px){
						margin: 0 0 10px 10px;
						font-size: 18px;
						background-size: 23px 23px;
						padding-left: 28px;
					}
					@include SP{
						padding-bottom: 0px;
						font-size: 22px;
						background-size: 26px 26px;
						margin: 0 0 22px 20px;
						padding-left: 34px;
					}
					@include max-screen(370px){
						font-size: 18px;
						background-size: 22px 22px;
						padding-left: 26px;
						margin: 0 0 10px 10px;
					}
				}
				// @include HOVER {
				// 	.ttl{
				// 		font-size: 28px;
				// 		background-size: 34px 34px;
				// 		padding-left: 40px;
				// 		padding-bottom: 0;
				// 	}
				// }
			}
			@include HOVER{
				opacity: 1;
				&:after{opacity: 1;}
				.ttl{
					bottom: 50%;left: 50%;
					margin: 0 ;
					@include transform_c(translate(-50%,50%));
				}
			}
		}
	}
}
/*-----------------------------------------------------------
NEWS
-------------------------------------------------------------*/
.sec-news{
	background: #F6F8FA;
	padding: 100px 0;
	@include screen(768px,1000px){padding: 80px 0;}
	@include SP{
		padding: 80px 0;
		overflow: hidden;
		.owl-dots{
			padding-left: 28px;
			margin-top: 10px !important;
		}
	}
	.owl-stage-outer,.owl-stage{
		width: 100%;overflow: visible;
	}
	.owl-stage{
		@include flexbox;
		@include PC{transform: none !important;}
	}
	.owl-item{

		width: calc(25% - 12px);
		@include SP{padding-right: 16px;}
		&:not(:nth-child(4n)){
			margin-right: 16px;
			@include SP{margin-right: 0;}
		}
	}
	.owl-carousel .owl-stage-outer{overflow: visible;}
	.news-lst{
		margin: 48px auto 50px;
		@include SP{
			margin: 32px auto 32px;
			padding: 0 30px 0 0;
		}
		.item{
			min-height: 100%;
			width: 100% !important;
		}
	}
	.c-btn{margin: 0 auto;}
}/*-----------------------------------------------------------
Body
-------------------------------------------------------------*/